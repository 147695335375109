:root {
  --piccolo: 78 70 180;
  /* #4e46b4 */
  --hit: 2 151 254;
  /* #40a69f */
  --beerus: 235 235 235;
  /* #ebebeb */
  --gohan: 255 255 255;
  /* #ffffff */
  --goten: 255 255 255;
  /* #ffffff */
  --goku: 245 245 245;
  /* #f5f5f5 */
  --bulma: 0 0 0;
  /* #000000 */
  --trunks: 153 156 160;
  /* #999ca0 */
  --popo: 0 0 0;
  /* #000000 */
  --jiren: 78 70 180 / 0.12;
  /* #4e46b4 */
  --heles: 0 0 0 / 0.04;
  /* #000000 */
  --zeno: 0 0 0 / 0.56;
  /* #000000 */

  /* support-colors  */
  --krillin: 255 179 25;
  /* #ff9800 */
  --krillin-60: 255 179 25 / 0.56;
  /* #ff9800 */
  --krillin-10: 255 179 25 / 0.08;
  /* #ff9800 */

  --chichi: 255 78 100;
  /* #ff4e64 */
  --chichi-60: 255 78 100 / 0.56;
  /* #ff4e64 */
  --chichi-10: 255 78 100 / 0.08;
  /* #ff4e64 */

  --roshi: 74 209 95;
  /* #2e7d32 */
  --roshi-60: 74 209 95 / 0.56;
  /* #2e7d32 */
  --roshi-10: 74 209 95 / 0.08;
  /* #2e7d32 */

  --dodoria: 211 48 48;
  /* #d33030 */
  --dodoria-60: 211 48 48 / 0.56;
  /* #d33030 */
  --dodoria-10: 211 48 48 / 0.08;
  /* #d33030 */

  --cell: 149 241 213;
  /* #95f1d5 */
  --cell-60: 149 241 213 / 0.56;
  /* #95f1d5 */
  --cell-10: 149 241 213 / 0.08;
  /* #95f1d5 */

  --raditz: 179 128 74;
  /* #b3804a */
  --raditz-60: 179 128 74 / 0.56;
  /* #b3804a */
  --raditz-10: 179 128 74 / 0.08;
  /* #b3804a */

  --whis: 52 72 240;
  /* #3448f0 */
  --whis-60: 52 72 240 / 0.56;
  /* #3448f0 */
  --whis-10: 52 72 240 / 0.08;
  /* #3448f0 */

  --frieza: 92 51 207;
  /* #5c33cf */
  --frieza-60: 92 51 207 / 0.56;
  /* #5c33cf */
  --frieza-10: 92 51 207 / 0.08;
  /* #5c33cf */

  --nappa: 114 85 80;
  /* #725550 */
  --nappa-60: 114 85 80 / 0.56;
  /* #725550 */
  --nappa-10: 114 85 80 / 0.08;
  /* #725550 */

  /* border-radius */
  --radius-i-xs: 0.25rem;
  /* 4px */
  --radius-i-sm: 0.5rem;
  /* 8px */
  --radius-i-md: 0.75rem;
  /* 12px */
  --radius-s-xs: 0.25rem;
  /* 4px */
  --radius-s-sm: 0.5rem;
  /* 8px */
  --radius-s-md: 0.75rem;
  /* 12px */
  --radius-s-lg: 1rem;
  /* 16px */

  /* border-width */
  --border-width: 0.0625rem;
  /* 1px */
  --border-i-width: 0.125rem;
  /* 2px */

  /* opacity */
  --opacity-moon: 0.32;

  /* fonts */
  --averta: "AvertaStd";

  /* box-shadow */
  --shadow-xs: 0 4px 12px -6px rgb(0 0 0 / 0.06);
  --shadow-sm: 0 6px 6px -6px rgb(0 0 0 / 0.16), 0 0 1px rgb(0 0 0 / 0.4);
  --shadow-md: 0 12px 12px -6px rgb(0 0 0 / 0.16), 0 0 1px rgb(0 0 0 / 0.4);
  --shadow-lg: 0 8px 24px -6px rgb(0 0 0 / 0.16), 0 0 1px rgb(0 0 0 / 0.4);
  --shadow-xl: 0 32px 32px -8px rgb(0 0 0 / 0.08),
    0 0 32px -8px rgb(0 0 0 / 0.12), 0 0 1px rgb(0 0 0 / 0.2);
  --nurse-gradient: "#0297FE";
}

@tailwind base;
@tailwind components;
@tailwind utilities;

hr {
  border-color: #ebebeb;
}

.cstHeight {
  height: calc(100vh - 490px);
}

.empl-calendar {
  height: calc(100vh - 577px);
}
.swaplisting {
  overflow-y: scroll;
  overflow-x: hidden;
}
.dropdownoption {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 360px;
  max-width: 173px;
}
.dropdownoption::-webkit-scrollbar {
  display: none;
}
.emloyeecrd {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 200px;
}
.emloyeecrd::-webkit-scrollbar {
  display: none;
}
.cstScroll {
  overflow-y: inherit !important;
}

.imgAvatarRounded .overflow-hidden {
  border-radius: 50%;
}

.disable {
  opacity: 0.3;
  pointer-events: none;
}

@layer components {
  .avatar-xs {
    @apply inline-flex items-center justify-center w-[20px] h-[20px] rounded-full text-moon-9 bg-blue-600 text-white;
  }
}

.bg-btn .bg-piccolo {
  background: rgba(36 156 186);
}

.simplebar-placeholder{
    height: auto !important;
}
.highlighted-table-row {
  /* border: 4px solid #0c3a40; or any color you prefer */
  color: #fff;
  background: #0297fe;
}
.notificationdrpP {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;
}
.notificationdrpP::-webkit-scrollbar {
  width: 8px;
}

.notificationdrpP::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the thumb (the draggable part) */
  border-radius: 24px;
}
.employCrd::-webkit-scrollbar {
  width: 8px;
}
.employCrd::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the thumb (the draggable part) */
  border-radius: 24px;
}
